import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: "/demo",
        name: "demo",
        component: () => import('../views/game/Demo')
    },
    {
        path: "/game/gid_:game_id",
        name: "game",
        component: () => import("../views/game/Play")
    },
    {
        path: "/game/gid_:game_id/pid_:promote_id",
        name: "promoteGame",
        component: () => import("../views/game/Play")
    },
    // 支付
    {
        path: '/pay/pay-spend',
        name: 'payPaySpend',
        component: () => import('../views/pay/Spend')
    },
    // 悬浮球
    {
        path: '/float',
        name: 'float',
        component: () => import('../views/Float')
    },
    //个人中心
    {
        path: '/personal/person',
        name: 'personalPerson',
        component: () => import('../views/personal/Person'),
    },
    //订单记录
    {
        path: '/personal/spendList',
        name: 'personalSpendList',
        component: () => import('../views/personal/SpendList'),
    },
    //小号管理
    {
        path: '/personal/subList',
        name: 'personalSubList',
        component: () => import('../views/personal/SubList'),
    },
    //修改密码
    {
        path: '/personal/password',
        name: 'personalPassword',
        component: () => import('../views/personal/Password'),
    },
    //换绑/绑定手机
    {
        path: '/personal/bind-phone',
        name: 'personalBindPhone',
        component: () => import('../views/personal/BindPhone'),
    },
    {
        path: '/personal/unbinding-phone',
        name: 'personalUnbindingPhone',
        component: () => import('../views/personal/UnbindingPhone'),
    },
    //显示绑定手机
    {
        path: '/personal/bound-phone',
        name: 'personalBoundPhone',
        component: () => import('../views/personal/BoundPhone'),
    },
    //实名认证
    {
        path: '/personal/auth',
        name: 'personalAuth',
        component: () => import('../views/personal/Auth'),
    },
    //钱包
    {
        path: '/personal/wallet',
        name: 'personalWallet',
        component: () => import('../views/personal/Wallet'),
    },
    //礼包
    {
        path: '/personal/gift',
        name: 'personalGift',
        component: () => import('../views/personal/Gift'),
    },
    {
        path: "/personal/gift-detail/:gift_id",
        name: "personalGiftDetail",
        component: () => import("../views/personal/GiftDetail")
    },
    //卡券
    {
        path: '/personal/voucher-card',
        name: 'personalVoucherCard',
        component: () => import('../views/personal/VoucherCard'),
    },
    //开服表
    {
        path: '/personal/server-list',
        name: 'personalServerList',
        component: () => import('../views/personal/ServerList'),
    },
    //活动列表
    {
        path: '/personal/activity',
        name: 'ActivityList',
        component: () => import('../views/personal/ActivityList'),
    },
    //活动详情
    {
        path: '/personal/activity-detail/:activity_id',
        name: 'ActivityDetail',
        component: () => import('../views/personal/ActivityDetail'),
    },
    //帮助中心
    {
        path: '/tool/help-center',
        name: 'toolHelpCenter',
        component: () => import('../views/tool/HelpCenter'),
    },
    //消息列表
    {
        path: '/tool/notice-list',
        name: 'toolNoticeList',
        component: () => import('../views/tool/NoticeList'),
    },
    //消息详情
    {
        path: '/tool/notice-detail/:notice_id',
        name: 'toolNoticeDetail',
        component: () => import('../views/tool/NoticeDetail'),
    },
    // 错误路由
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('../views/404.vue')
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,

});

export default router
